import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "./baseUrl";

const initialState = {
  onlineCheckIn: {},
  loading: false,
  error: null,
};

export const fetchOnlineCheckIn = createAsyncThunk(
  "onlineCheckIn/fetchOnlineCheckIn",
  async (language) => {
    const response = await axios.get(
      `${baseUrl}/api/OnlineCheckIn/${language}`
    );
    return response.data;
  }
);

const onlineCheckInSlice = createSlice({
  name: "onlineCheckIn",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchOnlineCheckIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchOnlineCheckIn.fulfilled, (state, action) => {
        state.loading = false;
        state.onlineCheckIn = action.payload;
      })
      .addCase(fetchOnlineCheckIn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default onlineCheckInSlice.reducer;
