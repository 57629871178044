import React, { useEffect, useRef, useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { Link, useNavigate, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faMaximize,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import Loader from "../../Loader";
import { Helmet } from "react-helmet-async";
import { Breadcrumbs, Typography, useMediaQuery } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import BookingForm from "../../components/BookingForm";
import { fetchAccommodation } from "./../../data/accommodation/accommodationSlice";
import { useDispatch, useSelector } from "react-redux";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";

const Accommodation = () => {
  const { hotelUrl } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const { accommodation, loading, error } = useSelector(
    (state) => state.accommodation
  );

  useEffect(() => {
    dispatch(fetchAccommodation({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//websdk.fastbooking-services.com/widgets/app.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {accommodation.pageDetails?.pageMetatagTitle || "accommodation"}
            </title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/${language}/${hotelUrl}/accommodation`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={accommodation.pageDetails?.pageMetatagDescription}
            />

            <link
              rel="stylesheet"
              property="stylesheet"
              href="//websdk.fastbooking-services.com/widgets/app.css"
            />
            <script
              type="text/javascript"
              src="//websdk.fastbooking-services.com/widgets/app.js"
            ></script>
            <style>{`
          .starting-from {
            display: flex !important;
            align-items: center !important;
            justify-content: space-around !important;
            max-width: 1000px !important;
            margin: 0 auto !important;
            padding: 1px !important;
            font-size: 13px !important;
            border: 0px solid #000 !important;
          }
          .starting-from .starting-from--price {
            font-size: 20px !important;
            color: #b0a06c !important;
          }
          .show-modal[data-v-05537ca1] {
            cursor: pointer;
            display: inline-block;
            line-height: 20px;
            min-width: 100px;
            padding: 9px 10px !important;
            text-align: center;
            vertical-align: middle;
          }
          .best-price .day--price {
            color: #008a00 !important;
            font-size: 10px !important;
          }
        `}</style>
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={
              accommodation.pageDetails?.pageBannerColorOverlayTo
            }
            style={{
              backgroundImage: `url(${
                isMobile
                  ? accommodation.pageDetails.pageBannerMobile
                  : isTablet
                  ? accommodation.pageDetails.pageBannerTablet
                  : accommodation.pageDetails.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{accommodation.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {t("Accommodation")}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <div className="text-15 uppercase mb-20">
                      <b>{t("Accommodation")}</b>
                    </div>
                    <h2 className="text-50 lg:text-40 md:text-30">
                      {accommodation.pageDetails?.pageTitle}
                    </h2>
                    <p>{accommodation.pageDetails?.pageText}</p>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="pb-5 mb-5">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {accommodation.roomsList?.map((room, index) =>
                    index % 2 === 0 ? (
                      <div className="rooms2 mb-90 " key={room.roomUrl}>
                        <figure>
                          <img
                            loading="lazy"
                            src={room.roomPhoto}
                            alt={room.roomName}
                            className="img-fluid"
                          />
                        </figure>
                        <div className="caption">
                          <h6>
                            <b>{room.roomTypeCategoryName}</b>
                          </h6>
                          {room.initialPrice > 0 && (
                            <h3>
                              {room.initialPrice}$ <span>/ {t("Night")} </span>
                            </h3>
                          )}
                          <h4>
                            <Link
                              to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                            >
                              {room.roomName}
                            </Link>
                          </h4>{" "}
                          <div className="row my-3">
                            <div className="d-flex flex-wrap row">
                              {room.roomSize && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faMaximize}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("Size")}: {room.roomSize}
                                </div>
                              )}
                              {room.maxOccupancy && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("Max Occupancy")}: {room.maxOccupancy}
                                </div>
                              )}

                              {room.roomView && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("View")}: {room.roomView}
                                </div>
                              )}
                              {room.roomBed && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faBed}
                                      className="me-2"
                                    />
                                  </b>
                                  {room.roomBed}
                                </div>
                              )}
                            </div>
                          </div>
                          <p>{room.roomSummery}</p>
                          <hr className="border-2" />
                          <div className="info-wrapper">
                            {room.roomPmscode && (
                              <>
                                {" "}
                                <div
                                  id={`fb-widget-${index}`}
                                  className="fb-widget"
                                  data-fbConfig={index}
                                ></div>
                                <script
                                  className="fb-widget-config"
                                  data-fbConfig={index}
                                  type="application/json"
                                >
                                  {JSON.stringify({
                                    params: [
                                      {
                                        startingFrom: {
                                          theme: "sf--thm-1",
                                          call2action: "none",
                                          roomRateFiltering: 2,
                                          rateFilter: [],
                                          roomFilter: [room.roomPmscode],
                                          dayRange: 180,
                                        },
                                        currency: "USD",
                                        locale: "en_GB",
                                        pricesDisplayMode: "normal",
                                        offerComputeRule: "lowerMinstay",
                                        maxAdults: 3,
                                        maxChildren: 2,
                                        mainColor: "#b0a06c",
                                        themeDark: false,
                                        openFrontInNewTab: true,
                                        property: accommodation.hotelPmscode,
                                        title: accommodation.hotelName,
                                        childrenMaxAge: 12,
                                        groupsNames: "egstellamakadihotels",
                                        quicksearch: {
                                          showChildrenAges: true,
                                        },
                                        fbWidget: "StartingFrom",
                                      },
                                    ],
                                    commonParams: {
                                      redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                      showPropertiesList: false,
                                      demoMode: false,
                                      allowGroupSelection: false,
                                      groupName: "",
                                    },
                                    _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                    propertyIndex: 0,
                                    version: "1.52.0",
                                    baseHost: `websdk.fastbooking-services.com`,
                                  })}
                                </script>
                              </>
                            )}
                            <div className="butn-dark">
                              {" "}
                              <Link
                                to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                              >
                                <span>{t("Book Now")} </span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="rooms2 mb-90 left" key={room.roomUrl}>
                        <figure>
                          <img
                            src={room.roomPhoto}
                            alt={room.roomName}
                            className="img-fluid"
                          />
                        </figure>
                        <div className="caption">
                          <h6>
                            <b>{room.roomTypeCategoryName}</b>
                          </h6>
                          <h4>
                            <Link
                              to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                            >
                              {room.roomName}
                            </Link>
                          </h4>{" "}
                          <div className="row my-3">
                            <div className="d-flex flex-wrap row">
                              {room.roomSize && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faMaximize}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("Size")}: {room.roomSize}
                                </div>
                              )}
                              {room.maxOccupancy && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("Max Occupancy")}: {room.maxOccupancy}
                                </div>
                              )}

                              {room.roomView && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="me-2"
                                    />
                                  </b>
                                  {t("View")}: {room.roomView}
                                </div>
                              )}
                              {room.roomBed && (
                                <div className="d-flex w-auto   align-items-center">
                                  <b>
                                    <FontAwesomeIcon
                                      icon={faBed}
                                      className="me-2"
                                    />
                                  </b>
                                  {room.roomBed}
                                </div>
                              )}
                            </div>
                          </div>
                          <p>{room.roomSummery}</p>
                          <hr className="border-2" />
                          <div className="info-wrapper">
                            {room.roomPmscode && (
                              <>
                                <div
                                  id={`fb-widget-${index}`}
                                  className="fb-widget"
                                  data-fbConfig={index}
                                ></div>
                                <script
                                  className="fb-widget-config"
                                  data-fbConfig={index}
                                  type="application/json"
                                >
                                  {JSON.stringify({
                                    params: [
                                      {
                                        startingFrom: {
                                          theme: "sf--thm-1",
                                          call2action: "none",
                                          roomRateFiltering: 2,
                                          rateFilter: [],
                                          roomFilter: [room.roomPmscode],
                                          dayRange: 180,
                                        },
                                        currency: "USD",
                                        locale: "en_GB",
                                        pricesDisplayMode: "normal",
                                        offerComputeRule: "lowerMinstay",
                                        maxAdults: 3,
                                        maxChildren: 2,
                                        mainColor: "#b0a06c",
                                        themeDark: false,
                                        openFrontInNewTab: true,
                                        property: accommodation.hotelPmscode,
                                        title: accommodation.hotelName,
                                        childrenMaxAge: 12,
                                        groupsNames: "egstellamakadihotels",
                                        quicksearch: {
                                          showChildrenAges: true,
                                        },
                                        fbWidget: "StartingFrom",
                                      },
                                    ],
                                    commonParams: {
                                      redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                      showPropertiesList: false,
                                      demoMode: false,
                                      allowGroupSelection: false,
                                      groupName: "",
                                    },
                                    _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                    propertyIndex: 0,
                                    version: "1.52.0",
                                    baseHost: `websdk.fastbooking-services.com`,
                                  })}
                                </script>
                              </>
                            )}
                            <div className="butn-dark">
                              {" "}
                              <Link
                                to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                              >
                                <span>{t("Book Now")}</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Accommodation;
