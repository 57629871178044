import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./ScrollToTop.jsx";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "react-image-lightbox/style.css";
import "./App.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LayoutGroup from "./components/LayoutGroup.jsx";
import HomeGroup from "./pages/HomeGroup.jsx";
import ContactUsGroup from "./pages/contact-us/ContactUsGroup.jsx";
import ContactUsHotels from "./pages/contact-us/ContactUsHotels.jsx";
import HomeHotel from "./pages/HomeHotel.jsx";
import Accommodation from "./pages/accommodation/Accommodation.jsx";
import AccommodationDetails from "./pages/accommodation/AccommodationDetails.jsx";
import Dining from "./pages/dining/Dining.jsx";
import DiningDetails from "./pages/dining/DiningDetails.jsx";
import Facilities from "./pages/facilities/Facilities.jsx";
import FacilitiesDetails from "./pages/facilities/FacilitiesDetails.jsx";
import MeetingAndEvents from "./pages/meeting-events/MeetingAndEvents.jsx";
import MeetingAndEventsDetails from "./pages/meeting-events/MeetingAndEventsDetails.jsx";
import OnlineCheckIn from "./pages/online-check-in/OnlineCheckIn.jsx";
import Faq from "./pages/faq/Faq.jsx";
import Gallery from "./pages/gallery/Gallery.jsx";
import Offer from "./pages/offer/Offer.jsx";
import OfferDetails from "./pages/offer/OfferDetails.jsx";
import LayoutHotels from "./components/LayoutHotels.jsx";
import { fetchHotels } from "./data/hotelsSlice.js";
import { fetchProperties } from "./data/bookingEngingSlice.js";
import DiningType from "./pages/dining/DiningType.jsx";
import Cruises from "./pages/cruises/Cruises.jsx";
import Spa from "./pages/spa/Spa";
import SpaDetails from "./pages/spa/SpaDetails.jsx";
import OffersGroup from "./pages/offer/OffersGroup.jsx";
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy";
import TermsAndConditions from "./pages/terms-and-conditions/TermsAndConditions";
import AllInclusive from "./pages/dining/AllInclusive.jsx";
import FaqHotels from "./pages/faq/FaqHotels.jsx";
import AboutUs from "./pages/AboutUs.jsx";
import { HelmetProvider } from "react-helmet-async";
import PageTracker from "./components/PageTracker.js";

import { setLanguage } from "./data/languageSlice.js";
import NotFound from "./pages/NotFound.jsx";

function App() {
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("/en");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchHotels(language));
    dispatch(fetchProperties());
  }, [dispatch, language]);
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments
    if (!window.location.href.includes("Sales-Blitz")) {
      if (pathSegments.length < 1) {
        const newPath = `/en${currentUrl.pathname}`;
        window.location.href = `${currentUrl.origin}${newPath}${currentUrl.search}`;
      } else {
        if (["en", "ar", "de", "ru", "fr"].includes(pathSegments[0])) {
          dispatch(setLanguage(pathSegments[0]));
          setBasename(`/${pathSegments[0]}`);
        } else {
          window.location.href = `${currentUrl.origin}/${language}${currentUrl.pathname}`;
        }
      }
    }
  }, [language, dispatch]);
  useEffect(() => {
    const head = document.head;
    const stylesheets = {
      ar: {
        id: "ar-stylesheet",
        href: "bootstrap-rtl.min.css",
      },
      ru: {
        id: "ru-stylesheet",
        href: "ru.css",
      },
      de: {
        id: "ru-stylesheet",
        href: "de.css",
      },
      fr: {
        id: "ru-stylesheet",
        href: "fr.css",
      },
    };

    // Remove all previously added stylesheets
    Object.values(stylesheets).forEach(({ id }) => {
      const existingLink = document.getElementById(id);
      if (existingLink) {
        head.removeChild(existingLink);
      }
    });
    if (stylesheets[language]) {
      const { id, href } = stylesheets[language];
      let link = document.createElement("link");
      link.id = id;
      link.type = "text/css";
      link.rel = "stylesheet";
      link.href = href;
      head.appendChild(link);
    }
  }, [language]);

  return (
    <HelmetProvider>
      {/* <Router basename="/en"> */}
      <Router basename={basename}>
        <ScrollToTop />
        <PageTracker />
        <Routes>
          <Route path="/" element={<LayoutGroup />}>
            <Route index element={<HomeGroup />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="online-check-in" element={<OnlineCheckIn />} />
            <Route path="contact-us" element={<ContactUsGroup />} />
            <Route path="nile-cruises" element={<Cruises />} />
            <Route path="offer" element={<OffersGroup />} />
            <Route path="faq" element={<Faq />} />

            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-conditions" element={<TermsAndConditions />} />
          </Route>
          <Route path="/:hotelUrl" element={<LayoutHotels />}>
            <Route index element={<HomeHotel />} />
            <Route path="contact-us" element={<ContactUsHotels />} />
            <Route path="gallery" element={<Gallery />} />
            <Route path="accommodation" element={<Accommodation />} />
            <Route
              path="accommodation/:roomUrl"
              element={<AccommodationDetails />}
            />{" "}
            <Route path="dining" element={<Dining />} />
            <Route path="all-inclusive" element={<AllInclusive />} />
            <Route path=":filterBy" element={<DiningType />} />
            <Route
              path="dining/:restaurantUrl"
              element={<DiningDetails />}
            />{" "}
            <Route path="facilities" element={<Facilities />} />
            <Route
              path="facilities/:facilityUrl"
              element={<FacilitiesDetails />}
            />{" "}
            <Route path="spa" element={<Spa />} />
            <Route path="spa/spa-details" element={<SpaDetails />} />
            <Route path="offer" element={<Offer />} />
            <Route path="offer/offer-details" element={<OfferDetails />} />
            <Route
              path="meetingandevents"
              element={<MeetingAndEvents />}
            />{" "}
            <Route
              path="meetingandevents/:facilityUrl"
              element={<MeetingAndEventsDetails />}
            />
            <Route path="faq" element={<FaqHotels />} />
          </Route>
          <Route path="*" element={<Navigate to={basename} replace />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
