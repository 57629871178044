import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Import your translation files
import en from "./languages/en.json";
import ar from "./languages/ar.json";
import de from "./languages/de.json";
import ru from "./languages/ru.json";
import fr from "./languages/fr.json";
const resources = {
  en: { translation: en },
  ar: { translation: ar },
  de: { translation: de },
  ru: { translation: ru },
  fr: { translation: fr },
};

i18n
  .use(LanguageDetector) // Detect language from browser or other sources
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
  });

export default i18n;
