import React, { Fragment, useEffect } from "react";
import { Container, Nav, Tab, Tabs } from "react-bootstrap";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import Fancybox from "../../components/fancybox-library/fancybox";
import styles from "./css/gallery.module.css";

import { Fade, Slide, Zoom } from "react-awesome-reveal";
import "./css/gallery.css";

import { Helmet } from "react-helmet-async";

import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchGallery } from "../../data/gallery/gallerySlice";
import { useMediaQuery } from "@mui/material";
import { margin } from "@mui/system";
import BookingForm from "../../components/BookingForm";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
const Gallery = () => {
  const { hotelUrl } = useParams();
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { gallery, loading, error } = useSelector((state) => state.gallery);
  useEffect(() => {
    dispatch(fetchGallery({ language, hotelUrl }));
  }, [dispatch, language, hotelUrl]);
  const groupImagesBySection = (galleries) => {
    const sections = {};

    // Ensure galleries is defined and is an array
    if (galleries && Array.isArray(galleries)) {
      galleries.forEach((image) => {
        if (!sections[image.gallerySectionName]) {
          sections[image.gallerySectionName] = [];
        }
        sections[image.gallerySectionName].push(image);
      });
    }

    return sections;
  };
  const sections = groupImagesBySection(gallery.galleries);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <Fragment>
          <Helmet>
            <title>{gallery.pageDetails?.pageMetatagTitle || "gallery"}</title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/${language}/${hotelUrl}/gallery`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={gallery.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={gallery.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? gallery.pageDetails?.pageBannerMobile
                  : isTablet
                  ? gallery.pageDetails?.pageBannerTablet
                  : gallery.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{gallery.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>
                <Link
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                  to={`/${hotelUrl}`}
                >
                  {hotelUrl === "stella-beach-resort-spa"
                    ? "Stella Beach Resort & Spa "
                    : "Stella Gardens Resort & Spa"}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {gallery.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-5 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    <h1 className="mt-2">{gallery.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="first-section py-100" id="gallery">
            <Container>
              <Tabs
                defaultActiveKey={t("All")}
                id="gallery-tabs"
                className="d-flex  justify-content-center w-100"
              >
                <Tab eventKey={t("All")} title={t("All")}>
                  <Fancybox>
                    <div className="row mt-30">
                      <div className="d-flex  flex-wrap">
                        {gallery.galleries?.map((image, idx) => (
                          <NavLink
                            key={idx}
                            data-fancybox={t("All")}
                            to={image.photoFile}
                            className="esg-center eg-post-157 eg-playa-element-0 esg-transition col-12 col-lg-4 p-3 mb-3 mb-lg-0"
                          >
                            <NavLink
                              className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii  ${styles.egplayaelement0a} `}
                              to={image.photoFile}
                            >
                              +
                            </NavLink>
                            <Zoom duration={2000} delay={0}>
                              <img
                                alt={image.gallerySectionName}
                                src={image.photoFile}
                                style={{ width: "339px", height: "300px" }}
                              />
                            </Zoom>
                          </NavLink>
                        ))}
                      </div>
                    </div>
                  </Fancybox>
                </Tab>
                {Object.entries(sections)?.map(
                  ([sectionName, images], index) => (
                    <Tab eventKey={sectionName} title={sectionName} key={index}>
                      <Fancybox>
                        <div className="row mt-30">
                          <div className="d-flex justify-content-center  flex-wrap">
                            {images.map((image, idx) => (
                              <NavLink
                                key={idx}
                                data-fancybox={sectionName}
                                to={image.photoFile}
                                className="esg-center eg-post-157 eg-playa-element-0 esg-transition col-12 col-lg-4 p-3 mb-3 mb-lg-0"
                              >
                                <NavLink
                                  className={`eg-playa-element-0 eg-post-157 esgbox esgboxffssii  ${styles.egplayaelement0a} `}
                                  to={image.photoFile}
                                >
                                  +
                                </NavLink>
                                <Zoom duration={2000} delay={0}>
                                  <img
                                    alt={image.gallerySectionName}
                                    src={image.photoFile}
                                    style={{
                                      width: "339px",
                                      height: "300px",
                                    }}
                                  />
                                </Zoom>
                              </NavLink>
                            ))}
                          </div>
                        </div>
                      </Fancybox>
                    </Tab>
                  )
                )}
              </Tabs>
            </Container>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};
export default Gallery;
