import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "./homeSlice";
import hotelsReducer from "./hotelsSlice";
import layoutGroupReducer from "./layoutGroupSlice";
import homeGroupReducer from "./homeGroup/homeGroupSlice";
import meetingsGroupReducer from "./meetingsGroup/meetingsGroupSlice";
import faqGroupReducer from "./faqGroup/faqGroupSlice";
import faqHotelsReducer from "./faqHotels/faqHotelsSlice";
import contactGroupReducer from "./contactGroup/contactGroupSlice";
import layoutHotelReducer from "./layoutHotelSlice";
import homeHotelReducer from "./homeHotel/homeHotelSlice";
import accommodationReducer from "./accommodation/accommodationSlice";
import accommodationDetailsReducer from "./accommodation/accommodationDetailsSlice";
import diningReducer from "./dining/diningSlice";
import allInclusiveReducer from "./all-inclusive/allInclusiveSlice";
import diningTypeReducer from "./diningtype/diningTypeSlice";
import diningDetailsReducer from "./dining/diningDetailsSlice";
import facilitiesReducer from "./facilities/facilitiesSlice";
import facilityDetailsReducer from "./facilities/facilityDetailsSlice";
import meetingsReducer from "./meetings/meetingsSlice";
import meetingDetailsReducer from "./meetings/meetingDetailsSlice";
import galleryReducer from "./gallery/gallerySlice";
import contactHotelReducer from "./contactHotel/contactHotelSlice";
import propertiesReducer from "./bookingEngingSlice";
import newsLetterReducer from "./newsLetterSlice";
import termsReducer from "./termsSlice";
import cruisesReducer from "./cruisesSlice";
import privacyReducer from "./privacySlice";
import aboutReducer from "./aboutSlice";
import languageReducer from "./languageSlice";
import onlineCheckInReducer from "./onlineCheckInSlice";
export const store = configureStore({
  reducer: {
    language: languageReducer,
    layoutGroup: layoutGroupReducer,
    properties: propertiesReducer,
    cruises: cruisesReducer,
    homeGroup: homeGroupReducer,
    meetingsGroup: meetingsGroupReducer,
    faqGroup: faqGroupReducer,
    about: aboutReducer,
    faqHotels: faqHotelsReducer,
    contactGroup: contactGroupReducer,
    layoutHotel: layoutHotelReducer,
    homeHotel: homeHotelReducer,
    home: homeReducer,
    hotels: hotelsReducer,
    accommodation: accommodationReducer,
    accommodationDetails: accommodationDetailsReducer,
    dining: diningReducer,
    allInclusive: allInclusiveReducer,
    diningType: diningTypeReducer,
    diningDetails: diningDetailsReducer,
    facilities: facilitiesReducer,
    facilityDetails: facilityDetailsReducer,
    meetings: meetingsReducer,
    meetingDetails: meetingDetailsReducer,
    gallery: galleryReducer,
    contactHotel: contactHotelReducer,
    newsLetter: newsLetterReducer,
    terms: termsReducer,
    privacy: privacyReducer,
    onlineCheckIn: onlineCheckInReducer,
  },
});
