import React, { Fragment, useEffect } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useMediaQuery } from "@mui/material";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchContactGroup } from "./../../data/contactGroup/contactGroupSlice";
import {
  faEnvelope,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { useTranslation } from "react-i18next";
const ContactUsGroup = () => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { contactGroup, loading, error } = useSelector(
    (state) => state.contactGroup
  );
  useEffect(() => {
    dispatch(fetchContactGroup(language));
  }, [dispatch, language]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {contactGroup.pageDetails?.pageMetatagTitle
                ? contactGroup.pageDetails?.pageMetatagTitle
                : "Contact Us"}
            </title>
            <link
              rel="canonical"
              href={`https://orienthg.com/${language}/contact-us`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={
                contactGroup.pageDetails?.pageMetatagDescription
                  ? contactGroup.pageDetails?.pageMetatagDescription
                  : ""
              }
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={
              contactGroup.pageDetails?.pageBannerColorOverlayTo
            }
            style={{
              backgroundImage: `url(${
                isMobile
                  ? contactGroup.pageDetails?.pageBannerMobile
                  : isTablet
                  ? contactGroup.pageDetails?.pageBannerTablet
                  : contactGroup.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    <h5>
                      <b>{t("Get in touch")}</b>{" "}
                    </h5>
                    <h1>{contactGroup.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section>
            {" "}
            <div
              role="presentation"
              className="breadcrumbs-wrapper py-3 bg-light-1"
            >
              <div className="container-fluid">
                <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                  <Link
                    className="d-flex align-items-center"
                    underline="hover"
                    sx={{ display: "flex", alignItems: "center" }}
                    color="inherit"
                    to={`/`}
                  >
                    <HomeIcon
                      sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                      fontSize="inherit"
                    />
                    {t("Home")}
                  </Link>

                  <Typography
                    sx={{ display: "flex", alignItems: "center" }}
                    color="text.primary"
                  >
                    {t("Contact Us")}
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </section>
          <section className="py-5 my-5">
            {" "}
            <div className="container">
              <div className="row text-center mb-60">
                <div className="col-md-12 mb-60">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h3>{contactGroup.pageDetails?.pageTitle}</h3>
                    <p>{contactGroup.pageDetails?.pageText}</p>
                  </Fade>
                  <Fade triggerOnce direction="up">
                    <div className="d-flex flex-wrap justify-content-around">
                      {" "}
                      {contactGroup.contactUsInfo?.groupContactUstelephone ? (
                        <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                          <div className="icon">
                            <span className="flaticon-call"></span>
                          </div>
                          <div className="text">
                            <p className="mb-2">{t("Reservation")} </p>{" "}
                            <Link
                              className="fs-6"
                              to={`tel:${contactGroup.contactUsInfo?.groupContactUstelephone}`}
                            >
                              {
                                contactGroup.contactUsInfo
                                  ?.groupContactUstelephone
                              }
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {contactGroup.contactUsInfo?.groupContactUsemail ? (
                        <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                          <div className="icon">
                            <span className="flaticon-envelope"></span>
                          </div>
                          <div className="text">
                            <p className="mb-2">{t("Email Info")} </p>{" "}
                            <Link
                              className="fs-6"
                              to={`mailto:${contactGroup.contactUsInfo?.groupContactUsemail}`}
                            >
                              {contactGroup.contactUsInfo?.groupContactUsemail}
                            </Link>
                          </div>
                        </div>
                      ) : null}
                      {contactGroup.contactUsInfo?.groupContactUsaddress ? (
                        <div className="reservations d-flex flex-column col-12 col-lg-4 justify-content-center align-items-center">
                          <div className="icon">
                            <span className="flaticon-location-pin"></span>
                          </div>
                          <div className="text">
                            <p className="mb-2">{t("Address")} </p>{" "}
                            <Link to="#." className="fs-6">
                              {
                                contactGroup.contactUsInfo
                                  ?.groupContactUsaddress
                              }
                            </Link>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Fade>
                </div>
              </div>
              <div className="row d-flex flex-wrap mx-auto   justify-content-around  align-items-center ">
                {contactGroup.hotelsContact?.map((contact, index) => (
                  <div
                    className="contact-box col-12 col-lg-5 mb-3 mt-3 mt-lg-0 mb-lg-0 "
                    key={index}
                  >
                    {contact.hotelGoogleMap ? (
                      <Fragment>
                        {" "}
                        <iframe
                          className="w-100"
                          height="350"
                          title={`${contact.hotelName} location`}
                          src={contact.hotelGoogleMap}
                          style={{ border: "0" }}
                          allowFullScreen=""
                          loading="lazy"
                        />
                        <hr style={{ border: "1px solid #ccc" }} />
                      </Fragment>
                    ) : null}
                    {contact.hotelName ? (
                      <Fragment>
                        {" "}
                        <h4 className="text-center">{contact.hotelName}</h4>
                        <hr style={{ border: "1px solid #ccc" }} />
                      </Fragment>
                    ) : null}
                    {contact.hotelPhone ? (
                      <div className="mb-2">
                        <Link to={`tel:${contact.hotelPhone}`}>
                          <FontAwesomeIcon icon={faPhone} className="me-2" />
                          {contact.hotelPhone}
                        </Link>
                      </div>
                    ) : null}
                    {contact.hotelEmail ? (
                      <div className="mb-2">
                        <Link to={`mailto:${contact.hotelEmail}`}>
                          <FontAwesomeIcon icon={faEnvelope} className="me-2" />{" "}
                          {contact.hotelEmail}
                        </Link>
                      </div>
                    ) : null}

                    {contact.hotelAddress ? (
                      <div className="mb-2">
                        <Link
                          to={contact.hotelGetDirectionLink}
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faLocationDot}
                            className="me-2"
                          />
                          {contact.hotelAddress}
                        </Link>
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default ContactUsGroup;
