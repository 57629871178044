import React, { useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import BookingForm from "../../components/BookingForm";
import { useMediaQuery } from "@mui/material";

import { fetchCruises } from "../../data/cruisesSlice";
import { useDispatch, useSelector } from "react-redux";
import { faEye, faUser } from "@fortawesome/free-regular-svg-icons";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Loader from "../../Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faDownload } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
const Cruises = () => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const dispatch = useDispatch();

  const { cruises, loading, error } = useSelector((state) => state.cruises);

  useEffect(() => {
    dispatch(fetchCruises(language));
  }, [dispatch, language]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {cruises.pageDetails?.pageMetatagTitle || t("Nile Cruises")}
            </title>
            <link
              rel="canonical"
              href={`https://orienthg.com/${language}/nile-cruises`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={cruises.pageDetails?.pageMetatagDescription}
            />
          </Helmet>
          <section
            className="banner-header section-padding valign bg-img bg-fixed"
            data-overlay-dark={cruises.pageDetails?.pageBannerColorOverlayTo}
            style={{
              backgroundImage: `url(${
                isMobile
                  ? cruises.pageDetails?.pageBannerMobile
                  : isTablet
                  ? cruises.pageDetails?.pageBannerTablet
                  : cruises.pageDetails?.pageBannerPC
              })`,
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-md-12 text-left caption mt-90">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h1>{cruises.pageDetails?.pageTitle}</h1>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {cruises.pageDetails?.pageTitle}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          <BookingForm />
          <section className="py-3 mt-5">
            <div className="container">
              <div className="row  mx-auto justify-content-center text-center">
                <div className="col-lg-10">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <h2
                      className="text-50 lg:text-40 md:text-30"
                      style={{ textTransform: "capitalize" }}
                    >
                      {cruises.pageDetails?.pageTitle}
                    </h2>
                    <p>{cruises.pageDetails?.pageText}</p>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          <section className="py-0 py-lg-0  ">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  {cruises.nileCruises?.map((cruise, index) =>
                    index % 2 === 0 ? (
                      <div
                        className="rooms2 mb-1 pt-0 mb-lg-5 pt-lg-5 "
                        key={index}
                        id={cruise.nileCruiseTitle}
                      >
                        <figure>
                          <img
                            src={cruise.nileCruisePhoto}
                            alt={cruise.nileCruiseTitle}
                            className="img-fluid  "
                            style={{
                              height: isMobile ? "auto" : "500px",
                              width: "100%",
                            }}
                          />
                        </figure>
                        <div className="caption">
                          <h4>
                            <Link
                              to={`/nile-cruises#${cruise.nileCruiseTitle}`}
                            >
                              {cruise.nileCruiseTitle}{" "}
                            </Link>
                          </h4>
                          <p>{cruise.nileCruiseDescription}</p>
                          <div className="row room-facilities">
                            <div className="col-md-12 ">
                              <ul className="  d-flex justify-between">
                                <li className="d-flex align-items-center">
                                  {" "}
                                  <span>
                                    {language === "ar" && (
                                      <i className="flaticon-bed me-2 fs-6"></i>
                                    )}{" "}
                                    {t("Cabins")}:{" "}
                                    {language !== "ar" && (
                                      <i className="flaticon-bed me-2 fs-6"></i>
                                    )}
                                  </span>{" "}
                                  {` ${cruise.nileCruiseNumOfCabins} `}{" "}
                                </li>
                                {cruise.nileCruiseNumOfSuites > 0 && (
                                  <li className="d-flex align-items-center">
                                    {" "}
                                    <span>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#b78e47",
                                          opacity: 0.8,
                                        }}
                                        className="me-2 fs-6"
                                        icon={faBed}
                                      />{" "}
                                      {t("Suits")}:{" "}
                                    </span>
                                    {cruise.nileCruiseNumOfSuites}
                                  </li>
                                )}
                                <li className="d-flex align-items-center">
                                  {" "}
                                  <a
                                    href={cruise?.factsheet}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ color: "#b78e47", opacity: 0.8 }}
                                      className="me-2 fs-6"
                                      icon={faDownload}
                                    />
                                    {t("Fact Sheet")}{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr className="border-2" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="rooms2 mb-1 pt-0  mb-lg-5 left pt-lg-5"
                        key={index}
                        id={cruise.nileCruiseTitle}
                      >
                        <figure>
                          <img
                            src={cruise.nileCruisePhoto}
                            alt={cruise.nileCruiseTitle}
                            className="img-fluid  "
                            style={{
                              height: isMobile ? "auto" : "500px",
                              width: "100%",
                            }}
                          />
                        </figure>
                        <div className="caption">
                          <h4>
                            <Link
                              to={`/nile-cruises#${cruise.nileCruiseTitle}`}
                            >
                              {cruise.nileCruiseTitle}{" "}
                            </Link>
                          </h4>
                          <p>{cruise.nileCruiseDescription}</p>
                          <div className="row room-facilities">
                            <div className="col-md-12">
                              <ul className="d-flex align-items-center justify-between">
                                <li className="d-flex align-items-center">
                                  {" "}
                                  <span>
                                    {language === "ar" && (
                                      <i className="flaticon-bed me-2 fs-6"></i>
                                    )}{" "}
                                    {t("Cabins")}:{" "}
                                    {language !== "ar" && (
                                      <i className="flaticon-bed me-2 fs-6"></i>
                                    )}
                                  </span>
                                  {cruise.nileCruiseNumOfCabins}
                                </li>{" "}
                                {cruise.nileCruiseNumOfSuites > 0 && (
                                  <li className="d-flex align-items-center">
                                    {" "}
                                    <span>
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#b78e47",
                                          opacity: 0.8,
                                        }}
                                        className="me-2 fs-6"
                                        icon={faBed}
                                      />{" "}
                                      {t("Suits")}:{" "}
                                    </span>
                                    {cruise.nileCruiseNumOfSuites}
                                  </li>
                                )}
                                <li className="d-flex align-items-center">
                                  {" "}
                                  <a
                                    href={cruise?.factsheet}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      style={{ color: "#b78e47", opacity: 0.8 }}
                                      className="me-2 fs-6"
                                      icon={faDownload}
                                    />
                                    {t("Fact Sheet")}{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <hr className="border-2" />
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Cruises;
