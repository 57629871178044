import React, { useEffect, useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import BookingForm from "../components/BookingForm";
import { Link, useParams } from "react-router-dom";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { fetchHomeHotel } from "./../data/homeHotel/homeHotelSlice";
import Loader from "./../Loader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBed,
  faClipboardList,
  faExpand,
  faMaximize,
  faUpRightAndDownLeftFromCenter,
  faUserCheck,
  faUtensils,
} from "@fortawesome/free-solid-svg-icons";
import { faClock, faEye, faUser } from "@fortawesome/free-regular-svg-icons";
import { Fade, Zoom } from "react-awesome-reveal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HomeIcon from "@mui/icons-material/Home";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
const createTiltHandler = (tiltRef) => {
  return {
    handleMove: (e) => {
      const el = tiltRef.current;
      const height = el.clientHeight;
      const width = el.clientWidth;

      const xVal = e.nativeEvent.offsetX;
      const yVal = e.nativeEvent.offsetY;

      const yRotation = 30 * ((xVal - width / 2) / width);
      const xRotation = -30 * ((yVal - height / 2) / height);

      const transformString = `perspective(1000px)  rotateX(${xRotation}deg) rotateY(${yRotation}deg) scale3d(1,1,1)`;
      el.style.transform = transformString;
    },

    handleMouseOut: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseDown: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },

    handleMouseUp: () => {
      const el = tiltRef.current;
      el.style.transform =
        "perspective(1000px)   rotateX(0) rotateY(0) scale3d(1,1,1)";
    },
  };
};
const HomeHotel = () => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.language.language);
  const { hotelUrl } = useParams();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("(max-width:767px)");
  const isTablet = useMediaQuery("(max-width:1024px)");
  const { homeHotel, loading, error } = useSelector((state) => state.homeHotel);
  useEffect(() => {
    dispatch(fetchHomeHotel({ hotelUrl, language }));
  }, [dispatch, hotelUrl, language]);
  const tiltRef1 = useRef(null);
  const tiltHandler1 = createTiltHandler(tiltRef1);
  const tiltRef2 = useRef(null);
  const tiltHandler2 = createTiltHandler(tiltRef2);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const options = {
    loop: true,
    margin: 10,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    dots: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
  };
  const roomOptions = {
    margin: 50,
    loop: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  const newsOptions = {
    loop: true,
    margin: 10,
    dots: true,
    autoplay: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200, // Adjust this value for smoother transitions
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 2,
      },
    },
  };
  const diningOptions = {
    margin: 40,
    loop: true,
    responsiveClass: true,
    nav: true,
    dots: true,
    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 5000,
    smartSpeed: 1200, // Adjust this value for smoother transitions
    autoplaySpeed: 1200,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <section
          style={{ backgroundColor: "#293839", padding: "200px 0 100px" }}
        >
          <h1 className="text-center text-light">{error}</h1>
        </section>
      ) : (
        <>
          <Helmet>
            <title>
              {homeHotel.metatagTitle ? homeHotel.metatagTitle : "Hotel"}
            </title>
            <link
              rel="canonical"
              href={`https://www.orienthg.com/${language}/${hotelUrl}`}
            />
            <meta name="robots" content="index, follow" />
            <meta
              name="description"
              content={
                homeHotel.metatagDescription ? homeHotel.metatagDescription : ""
              }
            />
            <link
              rel="stylesheet"
              property="stylesheet"
              href="//websdk.fastbooking-services.com/widgets/app.css"
            />
            <script
              type="text/javascript"
              src="//websdk.fastbooking-services.com/widgets/app.js"
            ></script>
            <style>{`
          .starting-from {
            display: flex !important;
            align-items: center !important;
            justify-content: space-around !important;
            max-width: 1000px !important;
            margin: 0 auto !important;
            padding: 1px !important;
            font-size: 13px !important;
            border: 0px solid #000 !important;
          }
          .starting-from .starting-from--price {
            font-size: 20px !important;
            color: #b0a06c !important;
          }
          .show-modal[data-v-05537ca1] {
            cursor: pointer;
            display: inline-block;
            line-height: 20px;
            min-width: 100px;
            padding: 9px 10px !important;
            text-align: center;
            vertical-align: middle;
          }
          .best-price .day--price {
            color: #008a00 !important;
            font-size: 10px !important;
          }
        `}</style>
          </Helmet>
          {/*Slider */}
          <header className="header slider-fade">
            <OwlCarousel dir="ltr" className="owl-theme" {...options}>
              {homeHotel.sliders?.map((slide, index) => (
                <div
                  key={index}
                  className="item bg-img"
                  style={{
                    backgroundImage: `url(${
                      isMobile
                        ? slide.sliderPhotoMobile
                        : isTablet
                        ? slide.sliderPhotoTablet
                        : slide.sliderPhoto
                    })`,
                  }}
                >
                  <div className="v-middle caption">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-10 offset-md-1 text-center">
                          <Fade triggerOnce cascade direction="up">
                            <span>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                              <i className="star-rating"></i>
                            </span>
                            <h4>{slide.sliderSubText}</h4>
                            <h1>{slide.sliderMainText}</h1>
                          </Fade>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </header>
          <div
            role="presentation"
            className="breadcrumbs-wrapper py-3 bg-light-1"
          >
            <div className="container-fluid">
              <Breadcrumbs aria-label="breadcrumb" className="ps-lg-4">
                <Link
                  className="d-flex align-items-center"
                  underline="hover"
                  sx={{ display: "flex", alignItems: "center" }}
                  color="inherit"
                  to={`/`}
                >
                  <HomeIcon
                    sx={{ mr: 0.5, fontSize: "17px", mb: 0.2 }}
                    fontSize="inherit"
                  />
                  {t("Home")}
                </Link>

                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  {homeHotel?.hotelName}
                </Typography>
              </Breadcrumbs>
            </div>
          </div>
          {/*Booking Search*/}
          <BookingForm />
          {/*About */}
          <section className="about py-5">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 mb-30 mt-lg-5 mb-lg-0">
                  <Fade triggerOnce cascade direction="up">
                    {" "}
                    <span>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                      <i className="star-rating"></i>
                    </span>
                    <div className="section-subtitle">
                      {homeHotel.hotelName}
                    </div>
                    <div className="section-title">
                      {homeHotel.sectionWelcomeTitle1}
                    </div>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `${homeHotel.sectionWelcomeTitleText}`,
                      }}
                    />
                    {/*<div className="reservations"><div className="icon">
                        <span className="flaticon-call"></span>
                      </div><div className="text">
                        <p>Reservation </p>
                        <Link to="tel:+2 065 359 0093/7">
                          +2 065 359 0093/7{" "}
                        </Link>
                      </div>  </div>{" "}*/}
                  </Fade>
                </div>
                <div
                  className="col col-md-3 "
                  ref={tiltRef1}
                  onMouseMove={tiltHandler1.handleMove}
                  onMouseOut={tiltHandler1.handleMouseOut}
                  onMouseDown={tiltHandler1.handleMouseDown}
                  onMouseUp={tiltHandler1.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade triggerOnce duration="1000">
                    <img
                      src={homeHotel.hotelPhoto}
                      alt=""
                      className="mt-60 mb-0"
                    />
                  </Fade>
                </div>
                <div
                  className="col col-md-3 "
                  ref={tiltRef2}
                  onMouseMove={tiltHandler2.handleMove}
                  onMouseOut={tiltHandler2.handleMouseOut}
                  onMouseDown={tiltHandler2.handleMouseDown}
                  onMouseUp={tiltHandler2.handleMouseUp}
                  style={{ transition: "none" }}
                >
                  <Fade triggerOnce duration="1000" delay="50">
                    {" "}
                    <img src={homeHotel.hotelPhoto2} alt="" />
                  </Fade>
                </div>
              </div>
            </div>
          </section>
          {/*Rooms */}
          <section className="rooms2 py-5 bg-cream" id="rooms">
            <div className="container">
              <div className="row">
                <Fade triggerOnce direction="left">
                  <div className="col-md-12">
                    <div className="section-subtitle">
                      <span>{homeHotel.sectionRoomTitleBack}</span>
                    </div>
                    <div className="section-title">
                      {homeHotel.sectionRoomTitle}
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel dir="ltr" className="owl-theme" {...roomOptions}>
                    {homeHotel.hotelRooms?.map((room, index) =>
                      index % 2 === 0 ? (
                        <div className="rooms2 " key={index}>
                          <figure>
                            <img
                              src={room.roomPhoto}
                              alt={room.roomName}
                              className="img-fluid"
                            />
                          </figure>
                          <div className="caption padding-left">
                            {room.initialPrice > 0 && (
                              <h3>
                                {room.initialPrice} ${" "}
                                <span>/ {t("Night")} </span>
                              </h3>
                            )}

                            <h4>
                              <Link
                                to={`/${room.hotelUrl}/accommodation/${room.roomUrl}}}`}
                              >
                                {room.roomName}
                              </Link>
                            </h4>
                            <p>{room.roomSummery}</p>
                            <div className="row amenitie-wrapper">
                              <div className="d-flex flex-wrap row">
                                {room.roomSize && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faMaximize}
                                      className="me-2"
                                    />{" "}
                                    {t("Size")}: {room.roomSize}
                                  </div>
                                )}
                                {room.maxOccupancy && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="me-2"
                                    />
                                    {t("Max Occupancy")}: {room.maxOccupancy}
                                  </div>
                                )}

                                {room.roomView && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="me-2"
                                      style={{
                                        opacity: "0.7",
                                        color: "#122223",
                                      }}
                                    />{" "}
                                    {t("View")}: {room.roomView}
                                  </div>
                                )}
                                {room.roomBed && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faBed}
                                      className="me-2"
                                      style={{
                                        opacity: "0.7",
                                        color: "#122223",
                                      }}
                                    />
                                    {room.roomBed}
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="border-2" />
                            <div className="info-wrapper">
                              <div className="more">
                                {room.roomPmscode && (
                                  <>
                                    {" "}
                                    <div
                                      id={`fb-widget-${index}`}
                                      className="fb-widget"
                                      data-fbConfig={index}
                                    ></div>
                                    <script
                                      className="fb-widget-config"
                                      data-fbConfig={index}
                                      type="application/json"
                                    >
                                      {JSON.stringify({
                                        params: [
                                          {
                                            startingFrom: {
                                              theme: "sf--thm-1",
                                              call2action: "none",
                                              roomRateFiltering: 2,
                                              rateFilter: [],
                                              roomFilter: [room.roomPmscode],
                                              dayRange: 180,
                                            },
                                            currency: "USD",
                                            locale: "en_GB",
                                            pricesDisplayMode: "normal",
                                            offerComputeRule: "lowerMinstay",
                                            maxAdults: 3,
                                            maxChildren: 2,
                                            mainColor: "#b0a06c",
                                            themeDark: false,
                                            openFrontInNewTab: true,
                                            property: homeHotel.hotelPmscode,
                                            title: homeHotel.hotelName,
                                            childrenMaxAge: 12,
                                            groupsNames: "egstellamakadihotels",
                                            quicksearch: {
                                              showChildrenAges: true,
                                            },
                                            fbWidget: "StartingFrom",
                                          },
                                        ],
                                        commonParams: {
                                          redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                          showPropertiesList: false,
                                          demoMode: false,
                                          allowGroupSelection: false,
                                          groupName: "",
                                        },
                                        _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                        propertyIndex: 0,
                                        version: "1.52.0",
                                        baseHost: `websdk.fastbooking-services.com`,
                                      })}
                                    </script>
                                  </>
                                )}
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                                >
                                  <span>{t("Book Now")}</span>
                                </Link>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="rooms2 left" key={index}>
                          <figure>
                            <img
                              src={room.roomPhoto}
                              alt={room.roomName}
                              className="img-fluid"
                            />
                          </figure>
                          <div className="caption padding-left">
                            {room.initialPrice > 0 && (
                              <h3>
                                {room.initialPrice} ${" "}
                                <span>/ {t("Night")}</span>
                              </h3>
                            )}

                            <h4>
                              <Link
                                to={`/${room.hotelUrl}/accommodation/${room.roomUrl}}}`}
                              >
                                {room.roomName}
                              </Link>
                            </h4>
                            <p>{room.roomSummery}</p>
                            <div className="row amenitie-wrapper">
                              <div className="d-flex flex-wrap row">
                                {room.roomSize && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faMaximize}
                                      className="me-2"
                                    />{" "}
                                    {t("Size")}: {room.roomSize}
                                  </div>
                                )}
                                {room.maxOccupancy && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faUser}
                                      className="me-2"
                                    />
                                    {t("Max Occupancy")}: {room.maxOccupancy}
                                  </div>
                                )}

                                {room.roomView && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      className="me-2"
                                      style={{
                                        opacity: "0.7",
                                        color: "#122223",
                                      }}
                                    />{" "}
                                    {t("View")}: {room.roomView}
                                  </div>
                                )}
                                {room.roomBed && (
                                  <div className="d-flex w-auto   align-items-center">
                                    <FontAwesomeIcon
                                      icon={faBed}
                                      className="me-2"
                                      style={{
                                        opacity: "0.7",
                                        color: "#122223",
                                      }}
                                    />
                                    {room.roomBed}
                                  </div>
                                )}
                              </div>
                            </div>
                            <hr className="border-2" />
                            <div className="info-wrapper">
                              <div className="more">
                                {room.roomPmscode && (
                                  <>
                                    {" "}
                                    <div
                                      id={`fb-widget-${index}`}
                                      className="fb-widget"
                                      data-fbConfig={index}
                                    ></div>
                                    <script
                                      className="fb-widget-config"
                                      data-fbConfig={index}
                                      type="application/json"
                                    >
                                      {JSON.stringify({
                                        params: [
                                          {
                                            startingFrom: {
                                              theme: "sf--thm-1",
                                              call2action: "none",
                                              roomRateFiltering: 2,
                                              rateFilter: [],
                                              roomFilter: [room.roomPmscode],
                                              dayRange: 180,
                                            },
                                            currency: "USD",
                                            locale: "en_GB",
                                            pricesDisplayMode: "normal",
                                            offerComputeRule: "lowerMinstay",
                                            maxAdults: 3,
                                            maxChildren: 2,
                                            mainColor: "#b0a06c",
                                            themeDark: false,
                                            openFrontInNewTab: true,
                                            property: homeHotel.hotelPmscode,
                                            title: homeHotel.hotelName,
                                            childrenMaxAge: 12,
                                            groupsNames: "egstellamakadihotels",
                                            quicksearch: {
                                              showChildrenAges: true,
                                            },
                                            fbWidget: "StartingFrom",
                                          },
                                        ],
                                        commonParams: {
                                          redirectUrl: `https://redirect.fastbooking.com/DIRECTORY/dispoprice.phtml`,
                                          showPropertiesList: false,
                                          demoMode: false,
                                          allowGroupSelection: false,
                                          groupName: "",
                                        },
                                        _authCode: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzY29wZXMiOiIuKiIsInByb3BlcnRpZXMiOiJlZ2NhaTI5MDQ4fGVnY2FpMjkwNDkiLCJncm91cHMiOiJeJCIsImZvciI6IkJhY2tvZmZpY2UiLCJpYXQiOjE3MjE3MzAwNTcsImppZCI6IjY2OWY2N2U5NTdiNDAifQ.q_LFcHtTWm1uwARSDlafNM2611rNj3Xxv1b67atfBRk`,
                                        propertyIndex: 0,
                                        version: "1.52.0",
                                        baseHost: `websdk.fastbooking-services.com`,
                                      })}
                                    </script>
                                  </>
                                )}
                              </div>
                              <div className="butn-dark">
                                {" "}
                                <Link
                                  to={`/${room.hotelUrl}/accommodation/${room.roomUrl}`}
                                >
                                  <span>{t("Book Now")}</span>
                                </Link>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>{" "}
          {/*Dining */}
          <section className="rooms3 py-5 " id="dining">
            <div className="container">
              <div className="row">
                <Fade triggerOnce direction="left">
                  <div className="col-md-12">
                    <div className="section-subtitle">
                      <span> {homeHotel.restaurants?.sectionDiningTitle}</span>
                    </div>
                    <div className="section-title">
                      {homeHotel.restaurants?.sectionDiningText}
                    </div>
                  </div>
                </Fade>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel
                    dir="ltr"
                    className="owl-theme"
                    {...diningOptions}
                  >
                    {homeHotel.restaurants?.restaurantList?.map(
                      (restaurant) => (
                        <div
                          key={restaurant.restaurantUrl}
                          className="square-flip"
                        >
                          <div
                            className="square bg-img"
                            style={{
                              backgroundImage: `url(${restaurant.restaurantPhoto})`,
                            }}
                          >
                            <span className="category">
                              <Link
                                to={`${
                                  restaurant.isMoreDetails
                                    ? `/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`
                                    : `/${restaurant.hotelUrl}/dining#${restaurant.restaurantUrl}`
                                }`}
                              >
                                {restaurant.restaurantsTypeName}
                              </Link>
                            </span>
                            <div className="square-container d-flex align-items-end justify-content-start">
                              <div className="box-title text-left">
                                <h4>{restaurant.restaurantName}</h4>
                              </div>
                            </div>

                            <div className="flip-overlay"></div>
                          </div>
                          <div className="square2 bg-white">
                            <div className="square-container2">
                              <h5 className="fw-bold">
                                {restaurant.restaurantName}
                              </h5>
                              <ul className="px-0 d-flex justify-content-left col-12 flex-wrap">
                                {restaurant.dressCode && (
                                  <li className="w-auto me-2">
                                    <b>
                                      {" "}
                                      <FontAwesomeIcon
                                        icon={faUserCheck}
                                        className="me-2"
                                      />
                                      {t("Dress Code")}:{" "}
                                    </b>
                                    {restaurant.dressCode}
                                  </li>
                                )}
                                {restaurant.restaurantCuisine && (
                                  <li className="w-auto me-2">
                                    <b>
                                      <FontAwesomeIcon
                                        icon={faUtensils}
                                        className="me-2"
                                      />
                                      {t("Cuisine")}:{" "}
                                    </b>
                                    {restaurant.restaurantCuisine}
                                  </li>
                                )}
                                {restaurant.restaurantOpenFor && (
                                  <li className="w-auto me-2">
                                    <b>
                                      <FontAwesomeIcon
                                        icon={faClock}
                                        className="me-2"
                                      />
                                      {t("Open For")}:{" "}
                                    </b>
                                    {restaurant.restaurantOpenFor}
                                  </li>
                                )}
                                {restaurant.restaurantMenu && (
                                  <li className="w-auto me-2">
                                    <b>
                                      <FontAwesomeIcon
                                        icon={faClipboardList}
                                        className="me-2"
                                      />
                                      {t("Menu")}:{" "}
                                    </b>
                                    {restaurant.restaurantMenu}
                                  </li>
                                )}
                              </ul>
                              <p>{restaurant.restaurantSummery}</p>
                              {restaurant.openinghours && (
                                <>
                                  <h6>
                                    <div className="page-list-icon">
                                      {" "}
                                      <span className="ti-time me-2"></span>{" "}
                                      {t("Opening Hours")}:
                                    </div>{" "}
                                  </h6>
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: `${restaurant.openinghours}`,
                                    }}
                                  />
                                </>
                              )}

                              {restaurant.isMoreDetails && (
                                <div className="btn-line">
                                  <Link
                                    to={`/${restaurant.hotelUrl}/dining/${restaurant.restaurantUrl}`}
                                  >
                                    {t("Details")}
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>{" "}
          <div className="container">
            <hr
              style={{ borderColor: "#b78e47", border: "2px dashed #b78e47" }}
            />
          </div>
          {/*Facilities */}
          <section className="rooms1 py-5" id="spa">
            <div className="container">
              <div className="row justify-content-between align-items-center">
                <Fade triggerOnce direction="left" className="col-md-6">
                  <div>
                    <div className="section-subtitle">
                      <span> {homeHotel.sectionActivitiesTitle}</span>
                    </div>
                    <div className="section-title">
                      {homeHotel.sectionActivitiesText}
                    </div>
                  </div>
                </Fade>
                <Fade
                  direction="right"
                  className={`col-md-6 ${
                    language == "ar" ? "text-lg-start" : "text-lg-end"
                  }`}
                  triggerOnce
                >
                  <Link to="facilities">
                    {" "}
                    <button
                      className={` butn-dark2 mb-4 mb-lg-0   `}
                      type="button"
                    >
                      <span>{t("View All")}</span>
                    </button>
                  </Link>
                </Fade>
              </div>
              <div className="row">
                {homeHotel.hotelFacilities?.map((facility, index) =>
                  index <= 2 ? (
                    <Zoom
                      className="col-md-4"
                      duration="1000"
                      key={facility.facilityUrl}
                      cascade
                    >
                      <div>
                        <div className="item">
                          <div className="position-re o-hidden">
                            <img
                              src={facility.facilityPhoto}
                              alt={facility.facilityName}
                            />
                          </div>

                          <div className="con">
                            <h5>
                              <Link
                                to={`${
                                  facility.isMoreDetails
                                    ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                    : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                                }`}
                              >
                                {facility.facilityName}
                              </Link>
                            </h5>
                            <div className="line"></div>
                            <div className="row facilities">
                              <div
                                className="col col-md-12 text-end"
                                style={{
                                  visibility:
                                    !facility.isMoreDetails && "hidden",
                                }}
                              >
                                <div className="permalink">
                                  <Link
                                    to={`/${hotelUrl}/facilities/${facility.facilityUrl}`}
                                  >
                                    {language == "ar" && (
                                      <i className="ti-arrow-right"></i>
                                    )}{" "}
                                    {t("Details")}{" "}
                                    {language != "ar" && (
                                      <i className="ti-arrow-right"></i>
                                    )}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Zoom>
                  ) : (
                    index > 2 &&
                    index < 5 && (
                      <Zoom
                        className="col-md-6"
                        duration="1000"
                        key={facility.facilityUrl}
                        cascade
                      >
                        <div>
                          <div className="item">
                            <div className="position-re o-hidden">
                              <img
                                src={facility.facilityPhoto}
                                alt={facility.facilityName}
                              />
                            </div>

                            <div className="con">
                              <h5>
                                <Link
                                  to={`${
                                    facility.isMoreDetails
                                      ? `/${facility.hotelUrl}/facilities/${facility.facilityUrl}`
                                      : `/${facility.hotelUrl}/facilities#${facility.facilityUrl}`
                                  }`}
                                >
                                  {facility.facilityName}
                                </Link>
                              </h5>
                              <div className="line"></div>
                              <div className="row facilities">
                                <div
                                  className="col col-md-12 text-end"
                                  style={{
                                    visibility:
                                      !facility.isMoreDetails && "hidden",
                                  }}
                                >
                                  <div className="permalink">
                                    <Link
                                      to={`/${hotelUrl}/facilities/${facility.facilityUrl}`}
                                    >
                                      {language == "ar" && (
                                        <i className="ti-arrow-right"></i>
                                      )}{" "}
                                      {t("Details")}{" "}
                                      {language != "ar" && (
                                        <i className="ti-arrow-right"></i>
                                      )}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Zoom>
                    )
                  )
                )}
              </div>
            </div>
          </section>
          {/*Facilities */}
          <section className="search-and-features-area bgc-lighter   rel z-1 ">
            <div className="container">
              <Fade triggerOnce direction="up" duration="1000" cascade>
                <div className="row justify-content-center">
                  {homeHotel.hotelServices?.map((service, index) => (
                    <div
                      className="col-xl-2 col-lg-3 col-md-4 col-6 col-small"
                      key={index}
                    >
                      <div
                        className="feature-item-three wow fadeInUp delay-0-2s animated"
                        style={{
                          visibility: "visible",
                          animationName: "fadeInUp",
                        }}
                      >
                        <div className="icon">
                          <img
                            src={service.serviceIcon}
                            alt="#"
                            style={{ width: "50px", maxWidth: "50px" }}
                            className="service-icon"
                          />
                        </div>
                        <h6>
                          <Link to="#.">{service.seviceName}</Link>
                        </h6>
                      </div>
                    </div>
                  ))}
                </div>
              </Fade>
            </div>
            <div className="bg-lines for-bg-white">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </section>
          {homeHotel.hotelNews?.length > 0 && (
            <section className="news section-padding " id="news">
              <div className="container">
                <div className="row">
                  <Fade triggerOnce direction="left">
                    <div className="col-md-12">
                      <div className="section-subtitle">
                        <span>{homeHotel.sectionNewsTitle}</span>
                      </div>
                      <div className="section-title">
                        <span className="text-dark">
                          {homeHotel.sectionNewsText}
                        </span>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <OwlCarousel
                      dir="ltr"
                      className="owl-theme"
                      {...newsOptions}
                    >
                      {homeHotel.hotelNews?.map((news) => (
                        <div key={news.newsUrl} className="item">
                          <div className="position-re o-hidden">
                            <img
                              src={news.newsPhoto}
                              alt={news.newsTitle}
                              className="w-100"
                            />
                            <div className="date">
                              <Link to="#."> {news.newsDateTime}</Link>
                            </div>
                          </div>
                          <div className="con">
                            <span className="category">
                              <Link to="#.">{news.newsType} </Link>
                            </span>
                            <h5>
                              <Link to="#.">{news.newsTitle}</Link>
                            </h5>
                            <hr className="border-2 my-2" />
                            <p className="text-secondary ">
                              <Link to="#.">{news.newsShortDescription}</Link>
                            </p>
                          </div>
                        </div>
                      ))}
                    </OwlCarousel>
                  </div>
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};
export default HomeHotel;
