import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./responsive.css";
import { store } from "./data/store";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);
